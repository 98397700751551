import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import {
  FaDatabase
} from "react-icons/fa";
import {
  FiHome,
  FiLogOut,
  FiArrowLeftCircle,
  FiArrowRightCircle,
} from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiCog } from "react-icons/bi";
import "../assets/styles/AppSidebar.css";

const AppSidebar = () => {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const location = useLocation();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  return (
    <div className={`sidebar-wrapper ${menuCollapse ? 'collapsed' : ''}`}>
      <Sidebar className='sidebar-wrapper' collapsed={menuCollapse}>
        <Menu className='sidebar-wrapper'>
          <MenuItem 
            className='sidebar-wrapper'
            active={location.pathname === '/dashboard'} 
            icon={<FiHome />}
            component={<Link to="/dashboard" />}
           
          >
            Dashboard
          </MenuItem>
          
          <MenuItem
            active={location.pathname === '/datasources'}
            icon={<FaDatabase />}
            component={<Link to="/datasources" />}
          >
            DataSources
          </MenuItem>
          
          <MenuItem
            active={location.pathname === '/analytics'}
            icon={<RiPencilLine />}
            component={<Link to="/analytics" />}
          >
            Analytics
          </MenuItem>
          
          <MenuItem
            active={location.pathname === '/admin'}
            icon={<BiCog />}
            component={<Link to="/admin" />}
          >
            Admin
          </MenuItem>
        </Menu>
        <div className="sidebar-header">
          <button className="collapse-btn" onClick={menuIconClick}>
            {menuCollapse ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
          </button>
        </div>        
      </Sidebar>
    </div>
  );
};

export default AppSidebar;
