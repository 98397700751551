import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import AppSidebar from '../components/AppSidebar';
import AppFooter from '../components/AppFooter';
import '../assets/styles/Layout.css';

const DefaultLayout = () => {
  return (
    <div className="layout-wrapper">
      <AppHeader />
      <AppSidebar />
      <div className="layout-content">
        <main className="main-content">
          <Outlet />
        </main>
        <AppFooter />
      </div>
    </div>
  );
};

export default DefaultLayout;
