import axios from 'axios';
import { CHAT_API_URL } from '../config';

const chatAPI = {
  // Send a message and get AI response
  sendMessage: async (message) => {
    try {
      const response = await axios.post(`${CHAT_API_URL}/chat/message`, {
        message,
        timestamp: new Date().toISOString()
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // Get chat history
  getChatHistory: async () => {
    try {
      const response = await axios.get(`${CHAT_API_URL}/chat/history`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  // Clear chat history
  clearHistory: async () => {
    try {
      const response = await axios.delete(`${CHAT_API_URL}/chat/history`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
};

export default chatAPI; 