import axios from 'axios';
import { API_URL } from '../config';

const dataSourceAPI = {
  listDatabases: async () => {
    try {
      const response = await axios.get(`${API_URL}/db/list_dbs`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const dbsList = JSON.parse(response.data.dbs_list);
      return dbsList;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  addDatabase: async (dbData) => {
    try {
      const requestData = {
        type: dbData.type,
        host: dbData.host,
        port: dbData.port,
        db_name: dbData.database,
        username: dbData.username,
        password: dbData.password,
        schema: dbData.schema,
        organization: dbData.organization,
        comments: dbData.comments
      };

      const response = await axios.post(`${API_URL}/db/add_db`, requestData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      if (error.response?.data?.error && error.response?.data?.details) {
        throw {
          message: error.response.data.error,
          details: error.response.data.details
        };
      }
      throw error.response?.data || error.message;
    }
  },

  deleteDatabase: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/db/datasources/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  updateDatabase: async (id, updatedData) => {
    try {
      const response = await axios.put(`${API_URL}/db/datasources/${id}`, updatedData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  toggleConnection: async (dbId, status) => {
    try {
      const response = await axios.post(`${API_URL}/data/toggle_connection`, {
        dbId,
        status
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
};

export default dataSourceAPI; 