import React, { useState, useRef, useEffect } from 'react';
import { FaComments, FaTimes, FaPaperPlane, FaExpandAlt, FaCompressAlt, FaTrash } from 'react-icons/fa';
import { MdMinimize } from 'react-icons/md';
import chatAPI from '../../api/chatAPI';
import './ChatWindow.css';

const ChatWindow = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatRef = useRef(null);

  // Fetch chat history when component mounts
  useEffect(() => {
    if (isOpen) {
      loadChatHistory();
    }
  }, [isOpen]);

  // Scroll to bottom when new messages arrive
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const loadChatHistory = async () => {
    try {
      const history = await chatAPI.getChatHistory();
      setMessages(history);
    } catch (error) {
      console.error('Failed to load chat history:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim() || isLoading) return;

    const userMessage = {
      text: message,
      sender: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setMessage('');
    setIsLoading(true);

    try {
      const response = await chatAPI.sendMessage(message);
      const aiResponse = {
        text: response.text,
        sender: 'ai',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, aiResponse]);
    } catch (error) {
      console.error('Failed to send message:', error);
      let err_msg = 'Sorry, I encountered an error. Please try again.'
      if(error){
        err_msg = 'Sorry, unable to send message. '+ error.error
      }


      setMessages(prev => [...prev, {
        text: err_msg,
        sender: 'ai',
        timestamp: new Date(),
        isError: true
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearHistory = async () => {
    if (window.confirm('Are you sure you want to clear the chat history?')) {
      try {
        await chatAPI.clearHistory();
        setMessages([]);
      } catch (error) {
        console.error('Failed to clear chat history:', error);
      }
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`chat-frame ${isOpen ? 'open' : ''} ${isExpanded ? 'expanded' : ''}`}>
      <div className="chat-frame-header">
        <div className="header-title">
          <FaComments className="header-icon" />
          <span>dataface</span>
        </div>
        <div className="header-actions">
          <button onClick={handleClearHistory} className="action-button" title="Clear History">
            <FaTrash />
          </button>
          <button onClick={toggleExpand} className="action-button">
            {isExpanded ? <FaCompressAlt /> : <FaExpandAlt />}
          </button>
          <button onClick={() => setIsOpen(!isOpen)} className="action-button">
            {isOpen ? <MdMinimize /> : <FaExpandAlt />}
          </button>
          <button onClick={() => setIsOpen(false)} className="action-button">
            <FaTimes />
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="chat-frame-content">
          <div className="chat-messages" ref={chatRef}>
            {messages.map((msg, index) => (
              <div 
                key={index} 
                className={`message ${msg.sender} ${msg.isError ? 'error' : ''}`}
              >
                <div className="message-content">{msg.text}</div>
                <div className="message-timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="message ai loading">
                <div className="typing-indicator">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            )}
          </div>
          
          <form onSubmit={handleSubmit} className="chat-input">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Ask me anything..."
              disabled={isLoading}
            />
            <button type="submit" disabled={!message.trim() || isLoading}>
              <FaPaperPlane />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatWindow; 