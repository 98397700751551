import axios from 'axios';
import { API_URL } from '../config'; // Assuming you have a config file with API_URL

// Use your existing API structure
const authAPI = {
  register: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/users/register`, {
        username: userData.username,
        email: userData.email,
        password: userData.password,
        full_name: userData.full_name // Optional field
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  },

  login: async (credentials) => {
    try {
      const response = await axios.post(`${API_URL}/users/login`, credentials);
      console.log('login response ', response)
      console.log('response ', response.data)
      localStorage.setItem('token', response.data.token)
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
};

export default authAPI; 