import React from 'react';
import { useAuth } from '../auth/AuthContext';

const Admin = () => {
  const { user } = useAuth();

  if (user?.role !== 'admin') {
    return <div>Access Denied</div>;
  }

  return (
    <div className="admin-container">
      <h1>Admin Panel</h1>
      <div className="admin-content">
        <div className="admin-section">
          <h2>User Management</h2>
          {/* Add user management components here */}
        </div>
        <div className="admin-section">
          <h2>System Settings</h2>
          {/* Add settings components here */}
        </div>
      </div>
    </div>
  );
};

export default Admin; 