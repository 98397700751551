import React, { useState, useEffect } from 'react';
import DataSourceCard from '../app/appcards/DataSourceCard';
import AddDbForm from '../app/components/AddDbForm';
import dataSourceAPI from '../api/dataSource';
import '../assets/styles/pages.css';

const DataSources = () => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [dataSources, setDataSources] = useState([]);
  const [editingSource, setEditingSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    try {
      setLoading(true);
      const dbsList = await dataSourceAPI.listDatabases();
      console.log('returned dbs', dbsList)
      // Transform the database list to match the card component format
      const transformedDbs = dbsList.filter(db => db.status!=='deleted').map(db => ({
        id: db.id, // Create unique ID from connection details
        name: db.db_name,
        type: db.db_type, // Since we know these are PostgreSQL databases
        host: db.host,
        port: db.port,
        database: db.db_name,
        username: db.username,
        isConnected: false // Default to disconnected state
      }));

      setDataSources(transformedDbs);
      setError(null);
    } catch (error) {
      console.error('Failed to fetch data sources:', error);
      setError('Failed to load databases. Please try again later.');
    } finally {
      setLoading(false);
    }
  };


  const handleAddDatabase = async (formData) => {
    try {
      await dataSourceAPI.addDatabase(formData);
      await fetchDataSources(); // Refresh the list
      setShowAddForm(false);
    } catch (error) {
      console.error('Failed to add database:', error);
    }
  };

  const handleEditSource = (source) => {
    setEditingSource(source);
    setShowAddForm(true);
  };

  const handleDeleteSource = async (sourceId) => {
    try {
      await dataSourceAPI.deleteDatabase(sourceId); // Call the delete API
      await fetchDataSources(); // Refresh the list
    } catch (error) {
      console.error('Failed to delete data source:', error);
    }
  };

  const handleUpdateSource = async (sourceId, updatedData) => {
    try {
      await dataSourceAPI.updateDatabase(sourceId, updatedData); // Call the update API
      await fetchDataSources(); // Refresh the list
    } catch (error) {
      console.error('Failed to update data source:', error);
    }
  };

  const handleToggleConnection = async (sourceId, newState) => {
    try {
      await dataSourceAPI.toggleConnection(sourceId, newState);
      setDataSources(prev =>
        prev.map(source =>
          source.id === sourceId
            ? { ...source, isConnected: newState }
            : source
        )
      );
    } catch (error) {
      console.error('Failed to toggle connection:', error);
      throw error;
    }
  };

  return (
    <div className="datasources-container">
      <div className="datasources-header">
        <div className="header-content">
          <h2>Data Sources</h2>
          <button 
            className="add-source-btn"
            onClick={() => {
              setEditingSource(null);
              setShowAddForm(true);
            }}
          >
            <span className="btn-icon">+</span>
            Add New Source
          </button>
        </div>
      </div>
      {showAddForm && (
        <AddDbForm 
          onClose={() => {
            setShowAddForm(false);
            setEditingSource(null);
          }}
          onSubmit={handleAddDatabase}
          initialData={editingSource}
        />
      )}

      {loading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading databases...</p>
        </div>
      ) : error ? (
        <div className="error-container">
          <p className="error-message">{error}</p>
        </div>
      ) : (
        <div className="datasources-grid">
          {dataSources.length === 0 ? (
            <div className="no-data-message">
              <p>No databases found. Click "Add New Source" to add your first database.</p>
            </div>
          ) : (
            dataSources.map(source => (
              <DataSourceCard 
                key={source.id}
                source={source}
                onEdit={handleEditSource}
                onDelete={handleDeleteSource}
                onToggleConnection={handleToggleConnection}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default DataSources; 