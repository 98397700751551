import React from 'react';
import '../assets/styles/AppFooter.css';

const AppFooter = () => {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <p>&copy; 2024 dataface.ai (a smartsastram pvt ltd product). All rights reserved.</p>
      </div>
    </footer>
  );
};

export default AppFooter;