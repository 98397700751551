import React, { useState, useEffect } from 'react';
import '../assets/styles/pages.css';
import DataSources from './DataSources';

const Dashboard = () => {
  const [totalDataSources, setTotalDataSources] = useState(0);
  const [totalActiveConnections, setTotalActiveConnections] = useState(0);

  const handleDataSourceCounts = (dataSources) => {
    setTotalDataSources(dataSources.length);
    setTotalActiveConnections(dataSources.filter(source => source.isConnected).length);
  };

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <div className="dashboard-content">
        <div className="dashboard-stats">
          <div className="stat-card">
            <h3>Total Data Sources</h3>
            <p>{totalDataSources}</p>
          </div>
          <div className="stat-card">
            <h3>Active Connections</h3>
            <p>{totalActiveConnections}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 