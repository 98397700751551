import React, { useState } from 'react';
import '../../assets/styles/AddDbForm.css';

const DATABASE_OPTIONS = [
  { value: 'mysql', label: 'MySQL' },
  { value: 'postgresql', label: 'PostgreSQL' },
  { value: 'mongodb', label: 'MongoDB' },
  { value: 'oracle', label: 'Oracle' }
];

const AddDbForm = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    type: 'mysql',
    host: '',
    port: '',
    database: '',
    username: '',
    password: '',
    schema: '',
    organization: '',
    comments: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({ message: '', details: '' });

  const validateForm = () => {
    //const formErrors = {};
    let failed = false;
    setError({message:'', details:''});
    //setFormErrors({});
    if (!formData.name.trim()) {
      failed = true
      formErrors.name = 'Name is required';
    }
    if (!formData.host.trim()) {
      failed = true
      formErrors.host = 'Host is required';
    }
    if (!formData.port.trim()) {
      failed = true
      formErrors.port = 'Port is required';
    } else if (isNaN(formData.port)) {
      failed = true
      formErrors.port = 'Port must be a number';
    }
    if (!formData.database.trim()) {
      failed = true
      formErrors.database = 'Database name is required';
    }
    if (!formData.username.trim()) {
      failed = true
      formErrors.username = 'Username is required';
    }
    if (!formData.password.trim()) {
      failed = true
      formErrors.password = 'Password is required';
    }
    if (!formData.schema.trim()) {
      failed = true
      formErrors.schema = 'Schema Name is required';
    }
    if (!formData.organization.trim()) {
      failed = true
      formErrors.organization = 'Organization is required';
    }
    
    return failed;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }else{
      setError('','')
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    console.info('errors', errors)
    
    if (errors==false) {
      
      setIsSubmitting(true);
      try {
        await onSubmit(formData);
        onClose();
      } catch (err) {
        // Split the error message if it contains a newline
        const [message, details] = err.message.split('\n');
        setError({
          message: message || 'Failed to add database',
          details: details || ''
        });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setError({ message: 'Please fix the form errors' });
    }
  };

  return (
    <div className="add-db-form-overlay">
      <div className="add-db-form-container">
        <div className="add-db-form-header">
          <h3>Add New Database</h3>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        
        {error.message && (
          <div className="error-message">
            <div className="error-title">{error.message}</div>
            {error.details && (
              <div className="error-details">{error.details}</div>
            )}
          </div>
        )}

        <form onSubmit={handleSubmit} className="add-db-form">
          <div className="form-group">
            <label htmlFor="name">Connection Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={formErrors.name ? 'error' : ''}
            />
            {formErrors.name && <span className="error-message">{formErrors.name}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="type">Database Type</label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
            >
              {DATABASE_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="host">Host</label>
            <input
              type="text"
              id="host"
              name="host"
              value={formData.host}
              onChange={handleChange}
              className={formErrors.host ? 'error' : ''}
            />
            {formErrors.host && <span className="error-message">{formErrors.host}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="port">Port</label>
            <input
              type="text"
              id="port"
              name="port"
              value={formData.port}
              onChange={handleChange}
              className={formErrors.port ? 'error' : ''}
            />
            {formErrors.port && <span className="error-message">{formErrors.port}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="database">Database Name</label>
            <input
              type="text"
              id="database"
              name="database"
              value={formData.database}
              onChange={handleChange}
              className={formErrors.database ? 'error' : ''}
            />
            {formErrors.database && <span className="error-message">{formErrors.database}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className={formErrors.username ? 'error' : ''}
            />
            {formErrors.username && <span className="error-message">{formErrors.username}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={formErrors.password ? 'error' : ''}
            />
            {formErrors.password && <span className="error-message">{formErrors.password}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="schema">Schema Name</label>
            <input
              type="text"
              id="schema"
              name="schema"
              value={formData.schema}
              onChange={handleChange}
              className={formErrors.schema ? 'error' : ''}
            />
            {formErrors.schema && <span className="error-message">{formErrors.schema}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="organization">Organization</label>
            <input
              type="text"
              id="organization"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              className={formErrors.organization ? 'error' : ''}
            />
            {formErrors.organization && <span className="error-message">{formErrors.organization}</span>}
          </div>

          <div className="form-group">
            <label htmlFor="comments">Comments</label>
            <textarea
              id="comments"
              name="comments"
              value={formData.comments}
              onChange={handleChange}
              className={formErrors.comments ? 'error' : ''}
            />
            {formErrors.comments && <span className="error-message">{formErrors.comments}</span>}
          </div>

          {formErrors.submit && <div className="error-message submit-error">{formErrors.submit}</div>}

          <div className="form-actions">
            <button type="button" onClick={onClose} className="cancel-button">
              Cancel
            </button>
            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? 'Adding...' : 'Add Database'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDbForm;