import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import authAPI from '../api/auth';
import { APP_LOGO, APP_NAME } from '../constants/assets';
import '../assets/styles/Auth.css';

const LoginControl = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  useEffect(() => {
    // Show registration success message if it exists
    if (location.state?.message) {
      setMessage(location.state.message);
      // Clear the message from location state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Basic validation
    if (!username.trim() || !password.trim()) {
      setError('Username and password are required');
      return;
    }

    try {
      const response = await authAPI.login({
        username,
        password
      });

      // Log the response to see its structure
      console.log('Login response:', response);

      // Check if we have both message and user data
      if (response && response.message === "Login successful" && response.user) {
        // Store user data in context
        login(response.user);
        
        // Navigate to dashboard
        navigate('/dashboard', { replace: true });
      } else {
        setError('Invalid response from server');
      }
    } catch (err) {
      console.error('Login error:', err);
      // Handle the error response from your API
      const errorMessage = err.response?.data?.error || 'Invalid credentials';
      setError(errorMessage);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleSubmit} className="auth-form">
        <div className="auth-logo" style={{height:"100px", width:"fit"}}>
          <img src={APP_LOGO} alt={APP_NAME}  style={{height:"100px", width:"380px"}}/>
        </div>
        {message && <div className="success-message">{message}</div>}
        {error && <div className="error-message">{error}</div>}
        
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
        <button type="submit" className="auth-button">
          Login
        </button>
        </div>

        <div className="auth-links">
          <Link to="/forgot-password">Forgot Password?</Link>
          <div className="register-link">
            Don't have an account? <Link to="/register">Register here</Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginControl;