import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './auth/AuthContext';
import ProtectedRoute from './auth/ProtectedRoute';
import DefaultLayout from './layout/DefaultLayout';
import LoginControl from './auth/LoginControl';
import Registration from './auth/Registration';
import ForgotPassword from './auth/ForgotPassword';
import { Dashboard, DataSources, Analytics, Admin } from './pages';
import './App.css';
import ChatWindow from './components/Chat/ChatWindow';

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginControl />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <DefaultLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="datasources" element={<DataSources />} />
            <Route path="analytics" element={<Analytics />} />
            <Route path="admin" element={<Admin />} />
          </Route>
        </Routes>
        <ChatWindow />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
