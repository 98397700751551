import React, { useState } from 'react';
import { FaDatabase, FaEdit, FaTrash, FaPlay, FaStop } from 'react-icons/fa';
import './DataSourceCard.css';

const DataSourceCard = ({ source, onDelete, onEdit, onToggleConnection }) => {
  const [isConnecting, setIsConnecting] = useState(false);

  const handleToggleConnection = async () => {
    setIsConnecting(true);
    try {
      await onToggleConnection(source.id, !source.isConnected);
    } catch (error) {
      console.error('Failed to toggle connection:', error);
    } finally {
      setIsConnecting(false);
    }
  };

  const getStatusColor = () => {
    if (isConnecting) return '#ffc107';
    return source.isConnected ? '#28a745' : '#dc3545';
  };

  const getTypeIcon = () => {
    switch (source.type.toLowerCase()) {
      case 'mysql':
        return '🐬';
      case 'postgresql':
        return '🐘';
      case 'mongodb':
        return '🍃';
      case 'oracle':
        return '☁️';
      default:
        return '💾';
    }
  };

  const handleDelete = async (source) => {
    if (window.confirm('Are you sure you want to delete this data source?')) {
      await onDelete(source.id);
    }
  };

  return (
    <div className="datasource-card">
      <div className="card-header">
        <div className="database-info">
          <span className="database-icon">{getTypeIcon()}</span>
          <div className="database-details">
            <h3>{source.name}</h3>
            <span className="database-type">{source.type}</span>
          </div>
        </div>
        <div 
          className="connection-status"
          style={{ backgroundColor: getStatusColor() }}
        />
      </div>

      <div className="card-content">
        <div className="connection-details">
          <p>
            <strong>Host:</strong> {source.host}:{source.port}
          </p>
          <p>
            <strong>Database:</strong> {source.database}
          </p>
          <p>
            <strong>Username:</strong> {source.username}
          </p>
        </div>
      </div>

      <div className="card-actions">
        <button
          className="action-button"
          onClick={handleToggleConnection}
          disabled={isConnecting}
          title={source.isConnected ? "Disconnect" : "Connect"}
        >
          {isConnecting ? (
            "Connecting..."
          ) : source.isConnected ? (
            <FaStop />
          ) : (
            <FaPlay />
          )}
        </button>
        <button
          className="action-button edit"
          onClick={() => onEdit(source)}
          title="Edit"
        >
          <FaEdit />
        </button>
        <button
          className="action-button delete"
          onClick={() => handleDelete(source)}
          title="Delete"
        >
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

export default DataSourceCard;
