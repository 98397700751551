import React from 'react';
import { useAuth } from '../auth/AuthContext';
import { APP_LOGO, APP_NAME } from '../constants/assets';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/AppHeader.css';

const AppHeader = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <header className="app-header">
      <div className="header-content">
        <div className="logo-container">
            <img src={APP_LOGO} alt={APP_NAME} className="App-logo-stable" />
          </div>          
        
        <div className="header-actions">
          <span className="user-name">{user?.username}</span>
          <button onClick={handleLogout} className="logout-button">
            Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
